@import '../../../../node_modules/antd/dist/antd.css';

.layout {
  min-height: 100vh;
  background: white;
}

.header {
  background: white;
  padding: 0 24px;
  box-shadow: 0 2px 8px #f0f1f2;
}

.content {
  padding: 24px;
}
