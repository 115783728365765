.editorToolbar {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #727272;
  color: white;
  background: #727272;
  min-height: 32px;
}

.editorToolbarLeftSide {
  display: flex;
  justify-content: flex-start;
  padding-left: 8px;
}

.editorToolbarRightSide {
  display: flex;
  justify-content: flex-end;
}

.editorToolbarButton span {
  color: white;
}

.editorToolbarButton:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}

.editorDivider {
  align-self: center;
  background-color: white;
}
