.splitPane {
  min-height: 80vh;
  flex-flow: row !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
