.aboutIcon svg {
  color: #107cf1;
}

.aboutTitle {
  color: #107cf1;
}

.aboutSectionTitle div {
  color: #107cf1;
}

.aboutSectionContent div span {
  display: block !important;
}

.aboutText {
  display: block;
  margin-bottom: 20px;
}
